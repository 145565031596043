<template>
  <TextbookPicker @textbook-selected="handleTextbookSelected" />
  <div class="chatContainer">
    <div ref="chatOutput" class="chatOutput">
      <div v-if="interactions.length === 0" class="receivedBox">
        Hello, my name is AURA. I am here to assist you in learning about your <span style="color: var(--classi2);">
          {{ selectedTextbookTitle }} textbook.</span> <br />
        Here are some examples of things you can ask me:
        <div class="example-queries">
          <p v-for="(query, index) in selectedTextbookExampleQueries" :key="index">
            <i>- {{ query }}</i>
          </p>
        </div>
      </div>
      <div v-for="(interaction, index) in interactions" :key="index">
        <p v-if="interaction.sent" class="sentBox" v-html="formatReceived(interaction.sent)"></p>
        <p v-if="interaction.received" class="receivedBox" v-html="formatReceived(interaction.received)"></p>
      </div>
      <div v-if="isWaitingForResponse" class="receivedBox typing-indicator">
        <span class="ellipsis">
          <span>.</span><span>.</span><span>.</span>
        </span>
      </div>
    </div>
    <div class="inputRow">
      <div class="inputWrapper">
        <textarea v-model="input" class="chatInput" placeholder="Chat with your textbook..."
          @keydown.enter.prevent="clickSendButton"></textarea>
        <span v-if="speechRecognitionSupported" @click="toggleDictation"
          class="material-symbols-outlined dictationButton" :class="{ recording: isDictating }">
          {{ 'mic' }}
        </span>
      </div>
      <span ref="sendButton" :disabled="isButtonDisabled" @click="sendMessage"
        class="material-symbols-outlined sendButton">
        send
      </span>
    </div>
  </div>
</template>

<script>
import io from "socket.io-client";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { throttle } from "lodash";
import TextbookPicker from "@/components/TextbookPicker.vue";
import { useStudentStore } from "@/store/studentData";
import axiosInstance from "@/utils/axiosInstance";

export default {
  components: {
    TextbookPicker,
  },
  emits: ["textbook-selected"],
  data() {
    return {
      selectedTextbookId: "",
      selectedTextbookTitle: "",
      selectedTextbookExampleQueries: [],
      socket: null,
      interactions: [],
      input: "",
      isButtonDisabled: false,
      isTypesetting: false,
      isWaitingForResponse: false,
      isDictating: false,
      speechRecognition: null,
      speechRecognitionSupported: false,
    };
  },
  computed: {
    studentStore() {
      return useStudentStore();
    },
  },
  created() {
    this.initializeSocket();
    this.checkSpeechRecognitionSupport();
  },
  methods: {
    initializeSocket() {      

      this.socket = io(process.env.VUE_APP_API_BASE_URL, {
        transports: ["polling", "websocket"],
        upgrade: true,
      });

      this.socket.on("connect", () => {
        console.log("WebSocket connected");
      });

      this.socket.on("response", (data) => {
        this.isWaitingForResponse = false;
        if (data.message === "[END]") {
          this.isButtonDisabled = false;
        } else {
          this.interactions[this.interactions.length - 1].received +=
            data.message;
          this.scrollToBottom();
        }
      });

      this.socket.on("disconnect", () => {
        console.log("WebSocket disconnected");
      });

      this.socket.on("connect_error", (error) => {
        console.error("WebSocket connection error:", error);
      });
    },
    handleTextbookSelected({ textbook_id, title, example_queries }) {
      this.selectedTextbookId = textbook_id;
      this.selectedTextbookTitle = title;
      this.selectedTextbookExampleQueries = example_queries;
      this.interactions = [];
      this.$emit("textbook-selected");
    },
    formatReceived(received) {
      const rawHtml = marked(received);
      return DOMPurify.sanitize(rawHtml);
    },
    sendMessage() {
      if (this.isButtonDisabled || this.input.trim() === "") return;
      this.isButtonDisabled = true;
      this.isWaitingForResponse = true;
      this.interactions.push({
        sent: this.input,
        received: "",
      });
      this.scrollToBottom();
      this.socket.emit("message", {
        userId: this.studentStore.studentId,
        firstName: this.studentStore.firstName,
        textbookId: this.selectedTextbookId,
        query: this.input,
      });
      this.input = "";

    },
    async playTextToSpeech(text) {
      try {
        const response = await axiosInstance.post('/chatbot/text-to-speech', {
          text: text, // Send the plain text
        }, {
          responseType: 'blob', // Ensure the response is treated as binary data
        });

        // Convert the blob into a playable audio URL
        const audioUrl = URL.createObjectURL(response.data);
        const audio = new Audio(audioUrl);

        // Play the audio
        audio.play();
      } catch (error) {
        console.error('Error playing text-to-speech audio:', error.message);
      }
    },
    clickSendButton() {
      this.$refs.sendButton.click();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.chatOutput;
        container.scrollTop = container.scrollHeight;
      });
    },

    typesetMath() {
      if (this.isTypesetting) return;

      this.isTypesetting = true;
      this.$nextTick(() => {
        if (window.MathJax) {
          if (this.$refs.chatOutput) {
            const latestInteraction = this.$refs.chatOutput.lastElementChild;
            window.MathJax.typesetPromise([latestInteraction])
              .then(() => {
                this.isTypesetting = false;
              })
              .catch((err) => {
                console.error("MathJax typesetting error:", err);
                this.isTypesetting = false;
              });
          }
        }
      });
    },
    throttledTypesetMath: throttle(function () {
      this.typesetMath();
    }, 1000),
    checkSpeechRecognitionSupport() {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

      if (SpeechRecognition) {
        this.speechRecognitionSupported = true;
        this.speechRecognition = new SpeechRecognition();
        this.speechRecognition.lang = 'en-US';
        this.speechRecognition.interimResults = false;
        this.speechRecognition.maxAlternatives = 1;

        this.speechRecognition.addEventListener('result', this.onSpeechResult);
        this.speechRecognition.addEventListener('end', this.onSpeechEnd);
        this.speechRecognition.addEventListener('error', this.onSpeechError);
      } else {
        this.speechRecognitionSupported = false;
      }
    },
    toggleDictation() {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (isSafari) {
        alert("Dictation is not supported in Safari. Please use a different browser.");
        return;
      }

      if (!this.speechRecognition) {
        alert("Speech recognition is not supported in this browser.");
        return;
      }

      if (this.isDictating) {
        this.speechRecognition.stop();
        this.isDictating = false;
      } else {
        this.speechRecognition.start();
        this.isDictating = true;
      }
    },
    onSpeechResult(event) {
      const transcript = Array.from(event.results)
        .map(result => result[0].transcript)
        .join('');
      this.input += transcript;
    },
    onSpeechEnd() {
      this.isDictating = false;
    },
    onSpeechError(event) {
      console.error("Speech recognition error:", event.error);
      this.isDictating = false;
    },
  },
  watch: {
    interactions: {
      handler() {
        this.throttledTypesetMath();
      },
      deep: true,
    },
  },
  updated() {
    this.scrollToBottom();
    this.throttledTypesetMath();
  },
  mounted() {
    this.throttledTypesetMath();
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
    }
    if (this.speechRecognition) {
      this.speechRecognition.abort();
    }
  },
};
</script>

<style scoped>
.chatContainer {
  width: 100%;
  margin: auto;
  font-size: 1.2vw;
  background-color: rgba(173, 216, 230, 0.5);
  border-radius: 2rem;
  padding: 1vw;
}

.chatOutput {
  overflow-y: auto;
  box-sizing: border-box;
  height: 70vh;
  width: 100%;
  max-width: 100%;
  padding: 1%;
  color: var(--secondary);
  border: none;
  border-radius: 1rem;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
}

.example-queries {
  margin-left: 3rem;
}

.receivedBox *,
.sentBox * {
  box-sizing: border-box;
}

.receivedBox,
.sentBox {
  line-height: 2;
  width: fit-content;
  max-width: 80%;
  padding: 2vh 3vw;
  margin-bottom: 2%;
  border-radius: 3rem;
}

.sentBox {
  margin-left: auto;
  margin-right: 0vw;
  background-color: var(--classi2);
  color: white;
}

.receivedBox {
  margin-right: auto;
  margin-left: 0vw;
  background-color: white;
  color: black;
}

.inputRow {
  display: flex;
  width: 100%;
  height: 6vh;
  margin-top: 0.5vh;
  justify-content: space-between;
  align-items: center;
}

.inputWrapper {
  position: relative;
  width: 89.5%;
  height: 100%;
}

textarea {
  font-family: var(--main-font);
  font-size: 1.2vw;
}

.chatInput {
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  font-size: 1.2vw;
  padding: 1vh 2vw;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
  border: none;
  padding-right: 3rem;
  /* Make space for the mic icon */
}

.chatInput:focus {
  border: 1px solid darkslategray;
  box-shadow: 0 0 10px var(--classi2);
}

.dictationButton {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--classi2);
  font-size: 1.8rem;
  transition: all ease 0.3s;
}

.dictationButton:hover {
  color: black;
}

.dictationButton.recording {
  animation: pulse 1s infinite;
  color: red;
}

@keyframes pulse {
  0% {
    transform: translateY(-50%) scale(1);
  }

  50% {
    transform: translateY(-50%) scale(1.2);
  }

  100% {
    transform: translateY(-50%) scale(1);
  }
}

.sendButton {
  cursor: pointer;
  display: flex;
  height: 100%;
  width: 10%;
  background-color: var(--classi2);
  color: white;
  border: none;
  border-radius: 1rem;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
}

.sendButton:hover,
.sendButton:active {
  opacity: 0.6;
}

.typing-indicator {
  display: flex;
  align-items: center;
}

.ellipsis {
  display: inline-block;
}

.ellipsis span {
  display: inline-block;
  font-size: 2rem;
  line-height: 0;
  animation: bounce 1s infinite;
}

.ellipsis span:nth-child(1) {
  animation-delay: 0s;
}

.ellipsis span:nth-child(2) {
  animation-delay: 0.2s;
}

.ellipsis span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {

  0%,
  60%,
  100% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-10px);
  }
}
</style>