<template>
  <div style="
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    " v-if="loading">
    <LoadingSpinner />
  </div>
  <div v-if="!loading" class="step-container">
    <div class="header">
      <div class="back-button" @click="goBack()">
        <span class="material-symbols-outlined">arrow_back</span>
      </div>
      <div>
        <div style="
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 1rem;
          ">
          <div class="navigation-info">Autograder > Assignment</div>
          <p class="assignment-title">{{ assignmentTitle }}</p>
        </div>
      </div>
    </div>

    <GradingOverview :overview-stats="overviewStats" :expanded="overviewExpanded" @expand="overviewExpanded = true"
      @collapse="overviewExpanded = false" />

    <!-- Tabs
    <div class="tabs">
      <div
        class="tab"
        :class="{ active: activeTab === 'submissions' }"
        @click="activeTab = 'submissions'"
      >
        Submissions ({{ Object.keys(submissions).length }})
        <span
          v-if="gradingComplete"
          class="material-symbols-outlined download-icon"
          @click="downloadAll"
          title="Download all"
          >download</span
        >
      </div>
      <div
        class="tab"
        :class="{ active: activeTab === 'questions' }"
        @click="activeTab = 'questions'"
      >
        Questions
      </div>
    </div> -->

    <!-- Question display
    <div v-if="activeTab === 'questions'" class="questions-viewer">
      <div class="viewer-header">
        <p class="total-marks">Total Marks: {{ totalMarks }}</p>
        <p>Due: {{ dueDate }}</p>
      </div>
      <div
        v-for="(question, index) in parsedQuestions"
        class="question-container"
        :key="index"
      >
        <div class="question-info" v-if="index !== 'grading'">
          <div class="question-header">
            <div class="section-break"></div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                color: var(--classi2);
              "
            >
              <p class="graded-question-section">
                Question {{ question.question_number }}
              </p>
              <div class="question-grade">
                Marks:
                <input
                  v-model.number="question.marks_possible"
                  type="number"
                  :min="0"
                  step="0.25"
                  @change="saveEdits(question.question_number)"
                />
              </div>
            </div>
          </div>
          <TeacherTiptap
            class="wysiwyg"
            v-model:content="question.question"
            :editable="editMode == question.question_number"
          />
          <div class="question-header">
            <div class="section-break"></div>
            <p class="graded-question-section">Correct Answer</p>
          </div>
          <TeacherTiptap
            class="wysiwyg"
            v-model:content="question.answer"
            :editable="editMode == question.question_number"
          />
          <div
            v-if="
              question.grading_instructions &&
              question.grading_instructions !== ''
            "
          >
            <div class="question-header">
              <div class="section-break"></div>
              <p class="graded-question-section">Grading Instructions</p>
            </div>
            <TeacherTiptap
              class="wysiwyg"
              v-model:content="question.grading_instructions"
              :editable="editMode == question.question_number"
            />

            <div
              v-if="editMode == question.question_number"
              style="display: flex; justify-content: space-around"
            >
              <div
                class="edit-button"
                @click="saveEdits(question.question_number)"
              >
                Save
              </div>
            </div>
            <div
              v-else
              class="edit-button"
              @click="editMode = question.question_number"
            >
              Click here to edit
              <span class="material-symbols-outlined edit-icon"> edit </span>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div v-if="activeTab === 'submissions'" class="submissions-viewer">
      <div v-if="Object.keys(submissions).length === 0" class="empty-state">
        <button class="upload-button" @click="showUploadModal = true">
          Upload Submissions
          <span class="material-symbols-outlined">upload</span>
        </button>
        <p>No submissions yet.</p>
      </div>
      <div v-else class="submissions-list">
        <div class="active-submission">{{ currentSubmissionName }}</div>
        <div v-for="(submission, fileName) in submissions" :key="fileName" class="submission-item"
          :class="{ active: currentSubmissionName === fileName }" @click="currentSubmissionName = fileName">
          {{ fileName }}
          <span v-if="fileName in submissions && submissions[fileName].graded" class="material-symbols-outlined"
            title="Graded">check_circle</span>
          <span v-else class="material-symbols-outlined" title="Ungraded">
            radio_button_unchecked
          </span>
        </div>
        <div class="add-submission-button" @click="$refs.fileInput.click()">
          <span style="font-size: 2vw" class="material-symbols-outlined">add_circle</span>
        </div>
        <input ref="fileInput" type="file" accept=".pdf" multiple class="hidden-file-input"
          @change="uploadExtraSubmissions" />
      </div>
      <div class="graded-container" v-for="(submission, fileName) in submissions" :key="fileName"
        v-show="currentSubmissionName === fileName">
        <div class="graded-header" v-if="
          gradedQuestions[fileName] && 'grading' in gradedQuestions[fileName]
        ">
          <div class="graded-total" v-if="isGraded(fileName)">
            {{ submissionTotals[fileName].obtained.toFixed(2) }} /
            {{ submissionTotals[fileName].possible.toFixed(2) }}
            ({{ submissionTotals[fileName].percentage }}%)
          </div>
          <div style="display: flex">
            <div v-if="!isGraded(fileName)" class="grade-question-button" @click="gradeSubmissions">
              Grade Assignments
              <span class="material-symbols-outlined">grading</span>
            </div>
            <div v-if="isGraded(fileName)" class="ai-button" :class="{ inactive: activeInsight !== fileName }"
              @click="getInsights($event, fileName)">
              AI Insights
              <span class="material-symbols-outlined">lightbulb_2</span>
              <div v-if="activeInsight === fileName" class="insight-popup" @click.stop>
                <div v-if="insightLoading" class="insight-loading">
                  <LoadingSpinner style="width: 3vw; height: 3vw; margin: auto" />
                </div>
                <div v-else class="insight-content">
                  {{ currentInsight }}
                </div>
              </div>
            </div>
            <div v-if="isGraded(fileName)" class="grade-question-button" @click="downloadPdf(fileName)">
              Download <span class="material-symbols-outlined">download</span>
            </div>
          </div>
        </div>
        <div v-if="submissions[fileName].loading" class="spinner-container">
          <LoadingSpinner class="feedback-spinner" />
        </div>
        <div v-for="(question, index) in gradedQuestions[fileName]" :key="index" class="graded-question">
          <div class="question-info" v-if="index !== 'grading'">
            <div class="question-header">
              <div class="section-break"></div>
              <p class="graded-question-section">
                Question {{ question.question_number }}
              </p>
            </div>
            <TeacherTiptap class="wysiwyg" v-model:content="question.question" :editable="false" />
            <div class="question-header">
              <div class="section-break"></div>
              <p class="graded-question-section">Response</p>
              <div class="show-answer-icon">
                <span class="material-symbols-outlined" @mousedown="() => {
                  showAnswerMode = question.question_number;
                }
                  " @mouseup="() => {
                    showAnswerMode = -1;
                  }
                    " @mouseleave="() => {
                      showAnswerMode = -1;
                    }
                      ">
                  visibility</span>
              </div>
            </div>
            <TeacherTiptap class="wysiwyg" v-if="showAnswerMode === question.question_number"
              v-model:content="question.answer" :editable="false" />
            <TeacherTiptap class="wysiwyg" v-else v-model:content="question.response" :editable="false" />
          </div>

          <div class="question-feedback" v-if="
            index !== 'grading' &&
            submissions[fileName].gradingStarted === true
          ">
            <div v-if="question.graded === 'inProgress'" class="spinner-container">
              <LoadingSpinner class="feedback-spinner" />
              <p class="loading-text">Grading in progress</p>
            </div>
            <div v-else>
              <div v-if="isGraded(fileName)" class="regrade-button"
                :class="{ inactive: activeRegrade !== `${fileName}-${question.question_number}` }">
                <Tooltip text="Regrade response" position="left" customStyles="width: 10vw; padding: 1vh 0">
                  <span class="material-symbols-outlined regrade-icon"
                    @click="regradeClick($event, fileName, question.question_number)"> cached </span>
                </Tooltip>
                <div v-if="activeRegrade === `${fileName}-${question.question_number}`" class="regrade-popup"
                  @click.stop>
                  Question {{question.question_number}}: Enter instructions for regrading <br />
                  <textarea class="regradeInstructionsInput" v-model="regradeInstructions"
                    placeholder="e.g. Award full marks if final answer is correct" />
                    <div class="regrade-options">
                      <div class="regrade-options-buttons" @click="regradeQuestion(fileName, question.question_number)">Regrade</div>
                    </div>                    
                </div>
              </div>
              <div class="question-grade">
                <input v-model.number="question.points_obtained" type="number" :min="0" :max="question.points_possible"
                  step="0.25" @change="updateResponse(fileName, question)" />
                / {{ question.points_possible }}
              </div>
              <div class="edit-controls">
                <div v-if="feedbackEditMode === question.question_number" class="edit-option"
                  @click="cancelFeedback(fileName, question.question_number)">
                  Cancel
                  <span class="material-symbols-outlined">close</span>
                </div>
                <div v-if="feedbackEditMode === question.question_number" class="edit-option"
                  @click="saveFeedback(fileName, question.question_number)">
                  Save
                  <span class="material-symbols-outlined">check</span>
                </div>
                <div v-else>
                  <span class="material-symbols-outlined"
                    @click="feedbackEditMode = question.question_number">edit</span>
                </div>
              </div>
              <TeacherTiptap class="wysiwyg" v-model:content="question.feedback"
                :editable="feedbackEditMode === question.question_number" whiteMode="true" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="flex-container">
      <div v-if="Object.keys(submissions).length > 0" class="submissions-list">
        <div class="submissions-list-heading">Submissions</div>
        <div
          v-for="(submission, fileName) in submissions"
          :key="fileName"
          class="submission-item"
          :class="{ active: currentSubmissionName === fileName }"
          @click="currentSubmissionName = fileName"
        >
          {{ fileName }}
          <span
            v-if="!submissionLoading"
            class="material-symbols-outlined"
            @click.stop="removeSubmission(fileName)"
            >close</span
          >
        </div>
      </div>
    </div> -->

    <!-- Upload Modal -->
    <div v-if="showUploadModal" class="modal-overlay" @click="showUploadModal = false">
      <div class="modal-content" @click.stop>
        <div class="modal-body">
          <div class="step-container">
            <div class="flex-container">
              <!-- Submissions List -->
              <div v-if="Object.keys(submissions).length > 0" class="submissions-list">
                <div v-for="(submission, fileName) in submissions" :key="fileName" class="submission-item"
                  :class="{ active: currentSubmissionName === fileName }" @click="currentSubmissionName = fileName">
                  {{ fileName }}
                  <span v-if="!submissionLoading" class="material-symbols-outlined"
                    @click.stop="removeSubmission(fileName)">
                    close
                  </span>
                </div>
              </div>

              <!-- Uploader Section -->
              <div class="uploader">
                <p class="step-heading">Upload submissions for grading</p>
                <p class="step-description">
                  Upload students' submissions in PDF form to be graded. These
                  could be typed or handwritten (scanned).
                </p>

                <!-- File Uploader for New Submissions -->
                <FileUploader v-if="Object.keys(submissions).length === 0" @files-selected="loadSubmissions" multiple
                  accept=".pdf" />

                <!-- File Display for Currently Selected Submission -->
                <FileDisplay v-if="currentSubmission" :fileUrl="currentSubmission.url" :isPDF="currentSubmission.isPDF"
                  :loading="submissionLoading" :success="submissionSuccess" fileType="Submission"
                  @remove="removeSubmission(currentSubmissionName)" @continue="uploadSubmissions" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import axiosInstance from "@/utils/axiosInstance";
import FileUploader from "@/components/FileUploader.vue";
import FileDisplay from "@/components/FileDisplay.vue";
import TeacherTiptap from "@/components/TeacherTiptap.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import GradingOverview from "@/components/GradingOverview.vue";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { generatePdf, generatePdfBlob } from "@/utils/pdfUtils";
import {
  getMaxScoreStudents,
  getMinScoreStudents,
  getMeanScore,
  getMedianScore,
  getQuestionCorrectFrequency,
} from "@/utils/gradingUtils";
import Tooltip from "@/components/Tooltip.vue"

export default {
  name: "SubmissionStep",
  components: {
    FileUploader,
    FileDisplay,
    TeacherTiptap,
    LoadingSpinner,
    GradingOverview,
    Tooltip
  },
  props: ["assignmentId"],
  data() {
    return {
      // Loading states
      loading: true,
      submissionLoading: false,
      submissionSuccess: false,
      gradingComplete: false,

      // UI state
      activeTab: "submissions",
      showUploadModal: false,
      feedbackEditMode: false,
      showAnswerMode: false,
      overviewExpanded: false,

      // Assignment data
      assignmentTitle: "",
      parsedQuestions: {},

      // Submissions data
      submissions: {},
      currentSubmissionName: "",
      gradedQuestions: {},
      pendingUploads: {},

      // AI Insights
      activeInsight: null,
      insightLoading: false,
      currentInsight: "",

      // Regrade
      activeRegrade: false,
      regradeInstructions: "",
    };
  },
  mounted() {
    this.getAssignmentQuestions();
    this.getPreviousSubmissions();

    document.addEventListener("click", this.handleClickOutside);
  },
  computed: {
    // Core submission data
    currentSubmission() {
      return this.submissions[this.currentSubmissionName] || null;
    },

    // Assignment statistics
    totalMarks() {
      return Object.values(this.parsedQuestions).reduce(
        (sum, q) => sum + q.marks_possible,
        0
      );
    },

    // Overview analytics
    overviewStats() {
      const submissionCount = Object.keys(this.submissions).length;
      const gradedCount = Object.values(this.submissions).filter(
        (sub) => sub.graded
      ).length;

      return {
        submissions: submissionCount,
        graded: gradedCount,
        min: getMinScoreStudents(this.gradedQuestions),
        max: getMaxScoreStudents(this.gradedQuestions),
        mean: getMeanScore(this.gradedQuestions),
        median: getMedianScore(this.gradedQuestions),
        questionCorrectFrequency: getQuestionCorrectFrequency(
          this.gradedQuestions
        ),
        scores: Object.keys(this.submissionTotals).map((fileName) =>
          this.submissionTotals[fileName].obtained.toFixed(2)
        ),
      };
    },
    // Check if a submission is graded
    isGraded() {
      return (fileName) => {
        if (!this.gradedQuestions[fileName]) return false;

        for (const question in this.gradedQuestions[fileName]) {
          if (
            question !== "grading" &&
            this.gradedQuestions[fileName][question].graded === false
          ) {
            return false;
          }
        }
        return true;
      };
    },

    // Calculate total points for each submission
    submissionTotals() {
      const totals = {};

      for (const fileName in this.gradedQuestions) {
        let obtained = 0;
        let possible = 0;

        for (const question in this.gradedQuestions[fileName]) {
          if (question !== "grading") {
            possible += parseFloat(
              this.gradedQuestions[fileName][question].points_possible || 0
            );
            obtained += parseFloat(
              this.gradedQuestions[fileName][question].points_obtained || 0
            );
          }
        }

        totals[fileName] = {
          obtained,
          possible,
          percentage:
            possible > 0 ? ((obtained / possible) * 100).toFixed(2) : 0,
        };
      }

      return totals;
    },
  },

  methods: {
    beforeUnmount() {
      document.removeEventListener("click", this.handleClickOutside);
    },
    handleClickOutside(event) {
      // Close AI Insights popup
      if (
        this.activeInsight &&
        !event.target.closest(".insight-popup") &&
        !event.target.closest(".ai-button")
      ) {
        this.activeInsight = null;
      }

      // Close Regrade popup
      if (
        this.activeRegrade &&
        !event.target.closest(".regrade-popup") &&
        !event.target.closest(".regrade-button")
      ) {
        this.activeRegrade = null;
      }
    },
    regradeClick(event, fileName, questionNumber) {
      event.stopPropagation();

      const uniqueIdentifier = `${fileName}-${questionNumber}`;

      if (this.activeRegrade === uniqueIdentifier) {
        this.activeRegrade = null;
        return;
      }

      this.activeRegrade = uniqueIdentifier;
      console.log(this.activeRegrade);
    },
    async regradeQuestion(fileName, questionNumber){

      this.activeRegrade = null;
      this.gradedQuestions[fileName][questionNumber].graded = 'inProgress';

      const response = await axiosInstance.post('/grader/regrade-question', {
        filename: fileName,
        assignment_id: this.assignmentId,
        question_number: questionNumber,
        regrade_instructions: this.regradeInstructions
      })

      const data = response.data;
      
      this.gradedQuestions[fileName][questionNumber].points_obtained = data.points_obtained;
      this.gradedQuestions[fileName][questionNumber].points_possible = data.points_possible;
      this.gradedQuestions[fileName][questionNumber].feedback = data.feedback;
      this.gradedQuestions[fileName][questionNumber].graded = true;

      this.calculateTotalPoints(fileName);
    },
    async getInsights(event, fileName) {
      event.stopPropagation();

      if (this.activeInsight === fileName) {
        this.activeInsight = null;
        return;
      }

      this.insightLoading = true;
      this.activeInsight = fileName;
      this.currentInsight = "";

      try {
        const response = await axiosInstance.post(
          "/grader/get-student-insights",
          {
            student_id: fileName,
          }
        );
        this.currentInsight = response.data.response;
      } catch (error) {
        console.error("Error fetching insights:", error);
        this.currentInsight = "Failed to load insights";
      } finally {
        this.insightLoading = false;
      }
    },

    async handleFileUpload(files) {
      for (const file of files) {
        const filename = file.name.replace(".pdf", "");
        this.pendingUploads[filename] = file;
      }
    },

    removePendingUpload(fileName) {
      delete this.pendingUploads[fileName];
    },

    async processUploads() {
      this.showUploadModal = false;

      // Add files to submissions with loading state
      for (const fileName in this.pendingUploads) {
        const file = this.pendingUploads[fileName];
        this.submissions[fileName] = {
          file,
          graded: false,
        };
      }

      // Clear pending uploads
      this.pendingUploads = {};

      // Process the submissions
      await this.uploadSubmissions();
    },

    async getAssignmentQuestions() {
      try {
        const response = await axiosInstance.post(
          "/grader/get-assignment-questions",
          { assignment_id: this.assignmentId }
        );
        const data = response.data;
        this.assignmentTitle = data["title"];
        for (const question of data["questions"]) {
          this.parsedQuestions[question.question_number] = question;
        }
      } catch (error) {
        console.error("Error fetching assignment questions:", error);
      }
    },

    async getPreviousSubmissions() {
      try {
        const response = await axiosInstance.post(
          "/grader/previous-questions-and-responses",
          { ms_id: this.assignmentId }
        );
        const data = response.data;
        this.gradedQuestions = data;

        for (const submission of Object.keys(data)) {
          if (!this.submissions[submission]) {
            this.submissions[submission] = {};
          }
          this.submissions[submission].graded = this.checkGraded(submission);
        }

        this.currentSubmissionName = Object.keys(this.submissions)[0];

        // Build points obtained and possible
        for (const fileName in data) {
          this.calculateTotalPoints(fileName);
          this.submissions[fileName].gradingStarted = true;
        }

        this.gradingComplete = true;

        // Trigger reactivity
        this.submissions = { ...this.submissions };
        this.gradedQuestions = { ...this.gradedQuestions };
      } catch (error) {
        console.error("Error going to assignment:", error);
      } finally {
        this.loading = false;
      }
    },

    calculateTotalPoints(fileName) {
      this.gradedQuestions[fileName]["grading"] = {
        possible: 0,
        obtained: 0,
      };
      for (const question in this.gradedQuestions[fileName]) {
        if (question !== "grading") {
          this.gradedQuestions[fileName]["grading"].possible += parseFloat(
            this.gradedQuestions[fileName][question].points_possible
          );
          this.gradedQuestions[fileName]["grading"].obtained += parseFloat(
            this.gradedQuestions[fileName][question].points_obtained
          );
        }
      }
    },

    goBack() {
      this.$router.push({
        name: "InstructorGrading",
      });
    },

    removeSubmission(fileName) {
      URL.revokeObjectURL(this.submissions[fileName].url);
      delete this.submissions[fileName];
      if (Object.keys(this.submissions).length === 0) {
        this.currentSubmissionName = null;
      } else if (this.currentSubmissionName === fileName) {
        this.currentSubmissionName = Object.keys(this.submissions)[0];
      }
    },

    loadSubmissions(files) {
      for (const file of files) {
        const isPDF = file.type === "application/pdf";
        const url = URL.createObjectURL(file);
        const filename = file.name.replace(".pdf", "").replace(" ", "_");
        this.submissions[filename] = {
          file: file,
          url: url,
          isPDF: isPDF,
          loading: true,
          gradingStarted: false,
          graded: false,
        };

        this.currentSubmissionName = filename;
      }
      // Trigger reactivity
      this.submissions = { ...this.submissions };
    },

    async uploadSubmissions() {
  this.submissionLoading = true;
  try {
    const uploadedFiles = []; // To store successfully uploaded file names
    let completedUploads = 0; // Track completed uploads for progress calculation

    // Helper function to get a signed URL and upload the file to GCS
    const uploadFileToGCS = async (fileName, file) => {
      try {
        // 1. Request a signed upload URL from the server
        const { data } = await axiosInstance.get('/grader/get-signed-url', {
          params: { file_name: fileName },
        });
        const uploadUrl = data.upload_url;

        // 2. Upload the file directly to GCS using the signed URL
        await axios.put(uploadUrl, file, {
          headers: { 'Content-Type': 'application/pdf' },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              ((completedUploads + progressEvent.loaded / progressEvent.total) / this.submissions.length) * 100
            );
            this.progressPercentage = percentCompleted;
          },
        });

        uploadedFiles.push(fileName);
        completedUploads++;
      } catch (error) {
        console.error(`Error uploading ${fileName}:`, error);
        this.submissions[fileName].loading = false;
      }
    };

    // Create an array of promises for parallel file uploads
    const uploadPromises = [];
    for (const fileName in this.submissions) {
      const submission = this.submissions[fileName];
      if (submission.loading) {
        uploadPromises.push(uploadFileToGCS(fileName, submission.file));
      }
    }

    // Perform parallel uploads
    await Promise.all(uploadPromises);

    // Once all uploads are completed, trigger processing on the server
    const processPayload = {
      assignment_id: this.assignmentId,
      file_names: uploadedFiles,
    };

    const processResponse = await axiosInstance.post('/grader/process-submission', processPayload, {
      headers: { 'Content-Type': 'application/json' },
    });

    const data = processResponse.data;

    // Process the response data
    for (const fileName in data) {
      if (this.submissions[fileName]) {
        this.submissions[fileName].loading = false;

        if (!this.gradedQuestions[fileName]) {
          this.gradedQuestions[fileName] = {};
        }

        this.gradedQuestions[fileName]['grading'] = {
          obtained: 0,
          possible: 0,
        };

        for (const question of data[fileName]) {
          question['response_id'] = -1;
          question['points_obtained'] = -1;
          question['points_possible'] = 0;
          question['feedback'] = '';
          question['original_feedback'] = '';
          question['graded'] = false;
          this.gradedQuestions[fileName][question['question_number']] = question;
        }

        this.submissions[fileName].loading = false;
      }
    }

    this.submissionLoading = false;
    this.submissionSuccess = true;
    this.showUploadModal = false;

    setTimeout(() => {
      this.submissionSuccess = false;
    }, 1000);

    // Trigger reactivity
    this.submissions = { ...this.submissions };
    this.gradedQuestions = { ...this.gradedQuestions };
  } catch (error) {
    console.error(`Error processing submissions:`, error);
    this.submissionLoading = false;
  }
},

    async uploadExtraSubmissions(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.loadSubmissions(files);
        await this.uploadSubmissions();
      }
    },

    checkGraded(fileName) {
      for (const question in this.gradedQuestions[fileName]) {
        if (this.gradedQuestions[fileName][question].graded === false) {
          return false;
        }
      }
      // this.submissions[fileName].graded = true;
      return true;
    },

    async gradeSubmissions() {
      this.gradingComplete = false;
      const submissions = Object.keys(this.gradedQuestions);

      // Mark all questions as 'inProgress'
      for (const fileName of submissions) {
        if (!this.checkGraded(fileName)) {
          for (const question in this.gradedQuestions[fileName]) {
            this.submissions[fileName].gradingStarted = true;
            if (question !== "grading") {
              this.gradedQuestions[fileName][question].graded = "inProgress";
              this.gradedQuestions[fileName][question].points_obtained = 0;
            }
          }
        }
      }

      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/grader/grade-submission`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              assignment_id: this.assignmentId,
              file_names: submissions,
            }),
          }
        );
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const gradedQuestion = JSON.parse(
            decoder.decode(value, { stream: true })
          );
          const {
            file_name,
            question_number,
            response_id,
            points_obtained,
            points_possible,
            feedback,
          } = gradedQuestion;

          console.log(gradedQuestion)

          // Ensure gradedQuestions[file_name] exists
          if (!this.gradedQuestions[file_name]) {
            this.gradedQuestions[file_name] = {};
          }

          // Update the graded question data
          this.gradedQuestions[file_name][question_number] = {
            response_id,
            points_obtained,
            points_possible,
            feedback,
            original_feedback: feedback,
            graded: true,
          };

          console.log(this.gradedQuestions)

          this.calculateTotalPoints(file_name);
          this.submissions[file_name].graded = this.checkGraded(file_name);
        }

        this.gradingComplete = true;

      } catch (error) {
        console.error("Error grading submission:", error);
      }
    },

    async updateResponse(fileName, question) {
      this.calculateTotalPoints(fileName);
      try {
        const response = await axiosInstance.post("/grader/update-response", {
          response_id: question.response_id,
          points_obtained: question.points_obtained,
          feedback: question.feedback,
        });
        if (response.status === 200) {
          console.log("Response updated successfully");
        } else {
          console.error("Failed to update response");
        }
      } catch (error) {
        console.error("Error updating response:", error);
      }
    },
    saveFeedback(fileName, question_number) {
      this.feedbackEditMode = -1;
      console.log(this.gradedQuestions[fileName][question_number]);
      this.gradedQuestions[fileName][question_number].original_feedback =
        this.gradedQuestions[fileName][question_number].feedback;
      this.updateResponse(
        fileName,
        this.gradedQuestions[fileName][question_number]
      );
    },
    cancelFeedback(fileName, question_number) {
      this.feedbackEditMode = -1;
      this.gradedQuestions[fileName][question_number].feedback =
        this.gradedQuestions[fileName][question_number].original_feedback;
    },
    downloadPdf(fileName) {
      generatePdf(fileName, this.gradedQuestions);
    },
    async downloadAll() {
      const zip = new JSZip();
      for (const fileName in this.gradedQuestions) {
        const pdfBlob = await generatePdfBlob(fileName, this.gradedQuestions);
        zip.file(`${fileName}_graded.pdf`, pdfBlob);
      }
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, "graded_submissions.zip");
    },
  },
};
</script>

<style scoped>
.step-container {
  box-sizing: border-box;
  padding: 2vw 2vw 0 0;
  overflow-y: hidden;
  height: 96vh;
  position: relative;
}

.header {
  width: 100%;
  height: 6vh;
  display: flex;
}

.back-button {
  display: flex;
  height: 100%;
  width: 6vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--classi2);
  border: 0.2rem solid var(--classi2);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-button:hover {
  background-color: var(--classi2);
  color: white;
}

.navigation-info {
  color: grey;
  font-size: 1.2vw;
}

.assignment-title {
  font-size: 1.7vw;
}

.tabs {
  display: flex;
  margin-bottom: 3vh;
}

.tab {
  flex: 1;
  padding: 2vh 2vw;
  font-size: 1.2vw;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab.active {
  border-bottom: 0.4rem solid var(--classi2);
}

.download-icon {
  padding-top: 0.4vh;
  padding-left: 0.3vw;
}

.download-icon:hover {
  color: var(--classi2);
  transform: scale(150%);
}

.flex-container {
  display: flex;
}

.uploader {
  flex: 1;
  max-width: 50vw;
  margin: 0 auto;
  padding-left: 2vw;
}

.step-heading {
  font-size: 1.5vw;
  margin-bottom: 1vw;
}

.step-description {
  font-size: 1.1vw;
  margin-bottom: 2vw;
}

.submissions-viewer {
  height: 100%;
  display: flex;
  justify-content: center;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.upload-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  color: white;
  background-color: var(--classi2);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.upload-button:hover {
  opacity: 0.8;
}

.mini-spinner {
  width: 1rem;
  height: 1rem;
}

.empty-state p {
  margin-top: 1rem;
  color: gray;
  font-size: 1vw;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--accent3);
  border-radius: 1rem;
  width: 70vw;
  height: 85vh;
  overflow-y: auto;
  padding: 2vh;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
}

.close-button {
  cursor: pointer;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1vw;
  margin-top: 2vh;
}

.pending-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh;
  background-color: var(--accent4);
  border-radius: 0.5rem;
  margin-bottom: 1vh;
}

.submissions-list {
  width: 13vw;
  height: 80vh;
  overflow-y: auto;
}

.submissions-list-heading {
  font-size: 1.4vw;
  padding: 1vw;
  border-bottom: 0.1rem solid var(--classi2);
}

.submission-item {
  /* background-color: lightgrey; */
  word-break: break-all;
  padding: 0.7rem;
  margin-bottom: 0.3rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 1rem;
  border: 0.1rem solid lightgrey;
  align-items: center;
  font-size: 1vw;
}

.submission-item:hover {
  background-color: var(--classi2);
  border: 0.1rem solid var(--classi2);
  color: white;
  /* transition: ease all 0.1s; */
}

.submission-item.active {
  border: 0.1rem solid var(--classi2);
}

.viewer-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
  font-size: 1.3vw;
  color: var(--classi2);
}

.title-date {
  text-align: center;
  color: var(--classi2);
}

.continue-button {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  color: var(--classi2);
  border: 0.1rem solid var(--classi2);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.continue-button:hover {
  background-color: var(--classi2);
  color: white;
}

.question-container {
  margin-bottom: 2vw;
  background-color: var(--accent3);
  /* border: 0.1rem solid var(--classi2); */
  border-radius: 2rem;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

/* Common question styles */
.question-info,
.question-feedback {
  padding: 1vh 1vw;
  font-size: 1vw;
}

.question-info {
  flex: 3;
}

.question-feedback {
  flex: 1;
  background-color: var(--classi2);
  color: white;
}

.question-header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1vw;
}

.section-break {
  width: 5%;
  height: 0.2vh;
  background-color: var(--classi2);
  margin-right: 1vw;
}

.graded-question-section {
  font-size: 1.3vw;
  color: var(--classi2);
}

.wysiwyg {
  margin-bottom: 2vh;
}

input[type="number"] {
  width: 5vw;
  font-size: 1.3vw;
  text-align: center;
  background-color: transparent;
  color: var(--classi2);
  border: 1px solid var(--classi2);
  border-radius: 0.5rem;
}

.edit-button {
  float: right;
  padding: 1vh 1vw;
  margin-bottom: 1vw;
  display: flex;
  align-items: center;
  font-size: 1.1vw;
  background-color: var(--classi2);
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
}

.edit-button:hover {
  opacity: 0.6;
  transition: ease all 0.3s;
}

.edit-icon {
  padding-left: 0.5vw;
}

.overview-tab.active {
  background-color: var(--classi2);
  color: white;
}

.overview-tab:hover {
  background-color: var(--classi2);
  color: white;
}

.hidden-file-input {
  display: none;
}

.add-submission-button,
.overview-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vh;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-submission-button:hover {
  color: var(--classi2);
}

.add-submission-button .material-symbols-outlined {
  margin-right: 0.5vw;
}

.graded-container {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 20vh;
}

.graded-header {
  display: flex;
  justify-content: space-between;
  margin: 1vh 2vw 2vw 2vw;
  height: 6vh;
}

.graded-total {
  /* background-color: var(--classi2); */
  /* box-shadow: var(--box-shadow); */
  border-radius: 1rem;
  text-align: center;
  font-size: 2vw;
  padding: 1vh 2vw;
  /* color: white; */
}

.grade-question-button {
  cursor: pointer;
  background-color: var(--classi2);
  color: var(--secondary);
  margin-left: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 2rem;
  transition: ease all 0.3s;
}

.grade-question-button:hover {
  transform: scale(105%);
  opacity: 0.6;
}

.graded-question {
  display: flex;
  margin: 0 2vw 2vw 2vw;
  border: none;
  border-radius: 2rem;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

.question-info {
  flex: 3;
  padding: 1.5vw 1vw;
  font-size: 0.9vw;
}

.question-feedback {
  flex: 1;
  background-color: var(--classi2);
  color: white;
  padding: 1vh 1vw;
  font-size: 0.9vw;
}

.question-header {
  display: flex;
  align-items: center;
  margin-bottom: 1vw;
}

.section-break {
  width: 5%;
  height: 2px;
  background-color: var(--classi2);
  margin-right: 1vw;
}

.graded-question-section {
  font-size: 1.3vw;
  color: var(--classi2);
}

.edit-controls {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.edit-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 1vw;
  border: 0.1vw solid var(--classi2);
  border-radius: 1rem;
  padding: 0.3vw;
  transition: all ease 0.3s;
}

.edit-option:hover {
  opacity: 0.6;
}

.material-symbols-outlined {
  cursor: pointer;
  font-size: 1.4vw;
  margin: 0 0.2vw;
  transition: all ease 0.3s;
}

.question-grade {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vw;
  margin: 1vw 0;
}

input[type="number"] {
  width: fit-content;
  font-size: 2vw;
  text-align: center;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 1rem;
  padding: 0.5rem;
  margin-right: 1vw;
}

.wysiwyg {
  margin-top: 1vh;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-text {
  margin-top: 2vh;
}

.show-answer-icon {
  margin-left: auto;
  cursor: pointer;
}

.show-answer-icon .material-symbols-outlined {
  font-size: 1.2vw;
  color: var(--classi2);
}

.ai-button {
  position: relative;
  cursor: pointer;
  background-color: var(--classi2);
  color: var(--secondary);
  margin-left: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  border-radius: 2rem;
  transition: ease all 0.3s;
}

.ai-button.inactive:hover {
  transform: scale(105%);
  opacity: 0.6;
}

.regrade-button {
  position: relative;
  margin-bottom: 1vh;
  text-align: right;
}

.regrade-icon:hover {
  transform: rotate(180deg) scale(110%);
}

.insight-popup {
  position: absolute;
  top: 100%;
  left: -10vw;
  transform: translateX(-50%);
  margin-top: 1rem;
  padding: 2vh 2vw;
  background-color: rgb(230, 226, 226);
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  width: 40vw;
  z-index: 1000;
  color: black;
  font-size: 1.1vw;
  line-height: 1.5rem;
  text-align: center;
  pointer-events: auto;
}

.regrade-popup {
  text-align: left;
  position: absolute;
  top: 1vh;
  right: 0;
  margin-top: 1rem;
  padding: 2vh 2vw;
  background-color: rgb(230, 226, 226);
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  width: 40vw;
  z-index: 1000;
  color: black;
  font-size: 1.1vw;
  line-height: 1.5rem;
  text-align: center;
  pointer-events: auto;
}

.regradeInstructionsInput {
  width: 100%;
  height: 10vh;
  margin-top: 1vh;
  padding: 2vh 1vw;
  font-family: var(--main-font);
  font-size: 0.8rem;
  border-radius: 1rem;
  border: none;
}

.regrade-options {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.regrade-options-buttons {
  background-color: var(--classi2);
  color: white;
  border-radius: 2rem;
  padding: 1vh 1vw;
  font-size: 0.9rem;
  cursor: pointer;
}

.active-submission {
  font-size: 1.3rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin: 1vh 2vw 3vh 0;
  padding-left: 1vw;
  /* padding-bottom: 1vh; */
  height: 6vh;
  border-bottom: 0.1rem solid var(--classi2);
  width: 100%;
}
</style>
