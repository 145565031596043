<template>
  <div>
    <div class="popup-overlay" @click="closePopup"></div>
    <div class="popup-window">
      <p class="popup-heading">New Assignment</p>

      <!-- Sliding button -->
      <div class="sliding-button">
        <div class="options">
          <div
            class="option"
            :class="{ active: assignmentType === 'document' }"
            @click="selectOption('document')"
          >
            Create from Document
          </div>
          <div
            class="option"
            :class="{ active: assignmentType === 'scratch' }"
            @click="selectOption('scratch')"
          >
            Create from Scratch
          </div>
        </div>
        <div class="slider" :style="sliderStyle"></div>
      </div>

      <!-- Create from scratch -->
      <div
        v-if="assignmentType === 'scratch'"
        class="scratch-form questions-viewer"
      >
        Coming soon
        <!-- <div class="step-indicators">
          <div
            v-for="step in 3"
            :key="step"
            :class="['step-indicator', { active: currentStep === step - 1 }]"
          ></div>
        </div>
        <div class="sliding-menu-container">
          <div
            class="sliding-menu"
            :style="{ transform: `translateX(-${currentStep * 33.33}%)` }"
          >
            
            <div class="step">
              <div class="form-group">
                <p class="step-field">Title</p>
                <input
                  type="text"
                  v-model="title"
                  id="title"
                  required
                  placeholder="Enter assignment title here..."
                />
              </div>
              <div class="form-group">
                <p class="step-field">Due Date</p>
                <input
                  type="date"
                  v-model="dueDate"
                  id="dueDate"
                  class="date-input"
                  style="width: 100%"
                  required
                />
              </div>
              <div class="nav-buttons" style="justify-content: flex-end">
                <div class="continue-button" @click="nextStep">
                  <span class="material-symbols-outlined">arrow_forward</span>
                </div>
              </div>
            </div>

            
            <div class="step step-overflow">
              <p class="step-heading">Add Questions</p>
              <div
                v-for="(question, index) in questions"
                :key="index"
                class="question-item"
              >
                <div class="question-row">
                  <div>Question {{ index + 1 }}</div>
                  <span
                    class="material-symbols-outlined remove-icon"
                    @click="removeQuestion(index)"
                    >delete</span
                  >
                  <div>
                    Marks:
                    <input
                      type="number"
                      v-model="question.marks"
                      class="marks-picker"
                    />
                  </div>
                </div>
                <TeacherTiptap
                  v-model:content="question.content"
                  :editable="true"
                />
              </div>
              <button @click="addQuestion" class="add-btn">
                <span class="material-symbols-outlined">add</span>
              </button>
              <div class="nav-buttons">
                <button @click="prevStep" class="prev-btn">
                  <span class="material-symbols-outlined">arrow_back</span>
                  Previous
                </button>
                <button @click="previewAssignment" class="create-btn">
                  Preview Assignment
                </button>
              </div>
            </div>

            
            <div class="step questions-viewer">
              <p class="step-heading">Preview</p>
              <p class="step-description">
                Please review and make any corrections if needed.
              </p>
              <div class="viewer-header">
                <p class="total-marks">Total Marks: {{ totalMarks }}</p>
                <div class="title-date">
                  <p>{{ title }}</p>
                  <p>Due: {{ formatDate(dueDate) }}</p>
                </div>
                <div
                  class="continue-button"
                  @click="createAssignment('scratch')"
                >
                  <span class="material-symbols-outlined">arrow_forward</span>
                </div>
              </div>
              
              <div
                v-for="(question, index) in questions"
                :key="index"
                class="question-container"
              >
                <div class="question-info">
                  <div class="question-header">
                    <div class="section-break"></div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        color: var(--classi2);
                      "
                    >
                      <p class="graded-question-section">
                        Question {{ index + 1 }}
                      </p>
                      <div class="question-grade">
                        Marks:
                        <input
                          v-model.number="question.marks"
                          type="number"
                          :min="0"
                          step="0.25"
                        />
                      </div>
                    </div>
                  </div>
                  <TeacherTiptap
                    class="wysiwyg"
                    v-model:content="question.content"
                    :editable="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <!-- Create from document -->
      <div v-else>
        <div v-if="!viewQuestions" class="uploader">
          <!-- <p v-if="!markingSchemeUrl" class="step-heading">
            Upload a marking scheme
          </p> -->
          <p v-if="!markingSchemeUrl" class="step-description">
            Upload a marking scheme in PDF form that will be used to guide the
            autograder. This could be typed or handwritten (scanned).
          </p>
          <div
            v-if="markingSchemeUrl"
            style="display: flex; justify-content: space-around"
          >
            <div>
              <p class="small-label">Title</p>
              <input
                v-model="markingSchemeTitle"
                type="text"
                placeholder="Enter assignment title here..."
                class="title-input"
              />
            </div>
            <div>
              <p class="small-label">Due Date</p>
              <input
                type="date"
                v-model="dueDate"
                id="dueDate"
                placeholder="Due Date"
                required
                class="date-input"
              />
            </div>
          </div>
          <FileUploader
            v-if="!markingSchemeUrl"
            @files-selected="(files) => loadMarkingScheme(files)"
          />
          <FileDisplay
            v-else
            :fileUrl="markingSchemeUrl"
            :isPDF="isMarkingSchemePDF"
            :loading="markingSchemeLoading"
            :success="markingSchemeSuccess"
            fileType="Marking Scheme"
            @remove="removeFile()"
            @continue="goToQuestionViewer()"
          />
        </div>
        <div v-else class="questions-viewer">
          <p class="step-heading">Preview</p>
          <p class="step-description">
            Please review and make any corrections if needed.
          </p>
          <div class="viewer-header">
            <p class="total-marks">Total Marks: {{ totalMarks }}</p>
            <div class="title-date">
              <p>{{ markingSchemeTitle }}</p>
              <p>Due: {{ dueDate }}</p>
            </div>
            <div class="continue-button" @click="createAssignment('document')">
              <span class="material-symbols-outlined">arrow_forward</span>
            </div>
          </div>
          <!-- Question display section -->
          <div
            v-for="(question, index) in parsedQuestions"
            class="question-container"
            :key="index"
          >
            <div class="question-info" v-if="index !== 'grading'">
              <div class="question-header">
                <div class="section-break"></div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    color: var(--classi2);
                  "
                >
                  <p class="graded-question-section">
                    Question {{ question.question_number }}
                  </p>
                  <div class="question-grade">
                    Marks:
                    <input
                      v-model.number="question.marks_possible"
                      type="number"
                      :min="0"
                      step="0.25"
                      @change="saveEdits(question.question_number)"
                    />
                  </div>
                </div>
              </div>
              <TeacherTiptap
                class="wysiwyg"
                v-model:content="question.question"
                :editable="editMode == question.question_number"
              />
              <div class="question-header">
                <div class="section-break"></div>
                <p class="graded-question-section">Correct Answer</p>
              </div>
              <TeacherTiptap
                class="wysiwyg"
                v-model:content="question.answer"
                :editable="editMode == question.question_number"
              />
              <div
                v-if="
                  question.grading_instructions &&
                  question.grading_instructions !== ''
                "
              >
                <div class="question-header">
                  <div class="section-break"></div>
                  <p class="graded-question-section">Grading Instructions</p>
                </div>
                <TeacherTiptap
                  class="wysiwyg"
                  v-model:content="question.grading_instructions"
                  :editable="editMode === question.question_number"
                />
              </div>

              <div
                v-if="editMode === question.question_number"
                style="display: flex; justify-content: space-around"
              >
                <div
                  class="edit-button"
                  @click="saveEdits(question.question_number)"
                >
                  Save
                </div>
              </div>
              <div
                class="edit-button"
                @click="editMode = question.question_number"
              >
                Click here to edit
                <span class="material-symbols-outlined edit-icon"> edit </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/utils/axiosInstance";
import { useInstructorStore } from "@/store/instructorData";
import TeacherTiptap from "./TeacherTiptap.vue";
import TiptapPreview from "./TiptapPreview.vue";
import FileUploader from "./FileUploader.vue";
import FileDisplay from "./FileDisplay.vue";
export default {
  components: {
    TeacherTiptap,
    TiptapPreview,
    FileUploader,
    FileDisplay,
  },
  data() {
    return {
      assignmentType: "document",
      title: "",
      description: "",
      dueDate: null,
      loading: false,
      fileUrl: null,
      isPDF: false, // To differentiate between PDF and image
      currentStep: 0,
      questions: [
        {
          content: null,
          marks: 0,
        },
      ],
      markingSchemeUrl: null,
      isMarkingSchemePDF: false,
      previousAssignments: [],
      markingSchemeLoading: false,
      markingSchemeSuccess: false,
      markingSchemeId: -1,
      markingSchemeTitle: "",
      currentFiles: {
        markingScheme: null,
      },
      parsedQuestions: {},
      viewQuestions: false,
      editMode: -1,
    };
  },
  computed: {
    instructorStore() {
      return useInstructorStore();
    },
    sliderStyle() {
      return {
        transform:
          this.assignmentType === "document"
            ? "translateX(0%)"
            : "translateX(100%)",
        width: "50%",
      };
    },
    totalMarks() {
      var total = 0;
      for (const question in this.parsedQuestions) {
        total += this.parsedQuestions[question]["marks_possible"];
      }
      return total;
    },
  },
  methods: {
    closePopup() {
      this.$emit("close");
      this.resetForm();
    },
    selectOption(option) {
      this.assignmentType = option;
      this.resetForm();
      // this.markingScheme
    },
    nextStep() {
      // Validate before advancing to the next step
      if (this.currentStep === 0) {
        if (!this.title || !this.dueDate) {
          alert("Title and due date are required.");
          return;
        }
      }

      if (this.currentStep < 2) {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },
    addQuestion() {
      this.questions.push({
        content: "",
        marks: 0,
      });
    },
    removeQuestion(index) {
      this.questions.splice(index, 1);
    },
    previewAssignment() {
      // Convert questions to the viewer format
      const total = this.questions.reduce((sum, q) => sum + q.marks, 0);

      // Navigate to preview step while maintaining viewer format consistency
      this.currentStep++;
    },
    async createAssignment(type) {
      var title = "";
      const dueDate = new Date(this.dueDate);
      dueDate.setUTCHours(23, 59, 59, 999);

      if (type === "document") {
        title = this.markingSchemeTitle;
      } else {
        title = this.title;
      }

      try {
        const response = await axiosInstance.post("/grader/add-assignment", {
          title: title,
          email: this.instructorStore.email,
          due: dueDate.toISOString(),
        });
        const data = response.data;
        if (response.status === 200) {
          this.$emit(
            "add-assignment",
            data.assignment_id,
            data.created,
            data.title,
            data.due
          );
          console.log("Assignment added successfully");
        } else {
          console.error("Failed to add assignment");
        }
      } catch (error) {
        console.error("Error adding assignment:", error);
      }

      this.closePopup();
    },
    resetForm() {
      this.title = "";
      this.description = "";
      this.dueDate = "";
      this.fileUrl = null;
      this.isPDF = false;
      this.currentStep = 0;
      this.questions = [];
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.loadFile(file);
    },
    handleDragOver(event) {
      document.getElementById("drop-zone").classList.add("drag-over");
    },
    handleDragLeave(event) {
      document.getElementById("drop-zone").classList.remove("drag-over");
    },
    handleDrop(event) {
      document.getElementById("drop-zone").classList.remove("drag-over");
      const file = event.dataTransfer.files[0];
      if (file) {
        this.loadMarkingScheme([file]);
      }
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    loadMarkingScheme(files) {
      const file = files[0];
      const isPDF = file.type === "application/pdf";
      const url = URL.createObjectURL(file);
      this.markingSchemeUrl = url;
      this.isMarkingSchemePDF = isPDF;
      this.currentFiles.markingScheme = file;
    },

    removeFile() {
      URL.revokeObjectURL(this.markingSchemeUrl);
      this.markingSchemeUrl = null;
      this.currentFiles.markingScheme = null;
      this.isMarkingSchemePDF = false;
    },

    async goToQuestionViewer() {
      if (!this.markingSchemeTitle || !this.dueDate) {
        alert("Please add a title and due date to continue");
      } else {
        await this.uploadMarkingScheme(this.currentFiles.markingScheme);
      }
    },

    async uploadMarkingScheme(file) {
      this.markingSchemeLoading = true;
      try {
        const formData = new FormData();
        const dueDate = new Date(this.dueDate);
        dueDate.setUTCHours(23, 59, 59, 999);

        formData.append("file", file);
        formData.append("title", this.markingSchemeTitle);
        formData.append("due", dueDate.toISOString());
        formData.append("created_by", this.instructorStore.email);
        formData.append("marking_scheme_id", this.markingSchemeId);

        const response = await axiosInstance.post(
          "/grader/process-marking-scheme",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        const data = response.data;
        this.markingSchemeId = data["marking_scheme_id"];

        for (const question of data["questions"]) {
          this.parsedQuestions[question.question_number] = question;
        }

        console.log(this.parsedQuestions);

        this.markingSchemeLoading = false;
        this.markingSchemeSuccess = true;

        setTimeout(() => {
          this.markingSchemeSuccess = false;
        }, 1000);

        this.viewQuestions = true;
      } catch (error) {
        console.error(`Error processing marking scheme:`, error);
        this.markingSchemeLoading = false;
      }
    },
    async saveEdits(question_number) {
      const question = this.parsedQuestions[question_number];

      try {
        const response = await axiosInstance.post("/grader/update-question", {
          question_id: question.question_id,
          question_number: question.question_number,
          question: question.question,
          answer: question.answer,
          grading_instructions: question.grading_instructions,
          marks_possible: question.marks_possible,
        });
        console.log(response.data);
        this.editMode = -1;
      } catch (error) {
        console.error("Error updating question:", error);
      }
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup-window {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 70vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--accent3);
  padding: 1vw;
  box-shadow: var(--box-shadow);
  z-index: 1001;
  border-radius: 1rem;
  overflow: auto;
}

.popup-heading {
  width: 100%;
  text-align: center;
  font-size: 1.4vw;
  padding-top: 1vh;
}

.sliding-button {
  position: relative;
  width: 50%;
  height: 5vh;
  margin: 2vh auto;
  background-color: var(--background-color);
  border-radius: 2rem;
  /* overflow: hidden; */
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.options {
  display: flex;
  height: 5vh;
}

.option {
  flex: 1;
  display: flex;
  height: 5vh;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  font-size: 1vw;
  transition: color 0.3s;
  z-index: 2;
}

.option.active {
  color: #fff;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: var(--classi2);
  border-radius: 2rem;
  transition: transform 0.3s;
  z-index: 1;
}

.scratch-form {
  padding: 2vw;
}

.sliding-menu-container {
  overflow-x: hidden;
}

.sliding-menu {
  display: flex;
  height: fit-content;
  transition: transform 0.3s ease;
  width: 300%;
  /* For a 3-step layout */
}

.step {
  width: 33.33%;
  /* For 3-step layout */
  height: 53vh;
  padding: 2vw;
  padding-bottom: 0;
  overflow: auto;
}

.step-heading {
  font-size: 1.7vw;
}

.step-field {
  padding-bottom: 1vw;
  font-size: 1.5vw;
}

.form-group {
  margin-bottom: 2vw;
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 1vw;
  border-radius: 1rem;
  border: none;
  font-size: 1.1vw;
  transition: border-color 0.3s ease;
}

.form-group input[type="text"]:focus,
.form-group textarea:focus {
  border-color: var(--classi2);
  outline: none;
}

.nav-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2vw;
}

button:hover {
  transition: 0.3 all ease;
  opacity: 0.6;
}

.next-btn,
.prev-btn,
.create-btn {
  padding: 1vw 2vw;
  float: right;
  border: none;
  border-radius: 1rem;
  font-size: 1vw;
  cursor: pointer;
  display: flex;
  /* Add flexbox */
  align-items: center;
  /* Vertically align items */
  justify-content: center;
  /* Horizontally center items */
  gap: 0.5vw;
  /* Space between text and arrow */
}

.next-btn,
.create-btn {
  background-color: var(--classi2);
  color: white;
}

.prev-btn {
  background-color: #f0f0f0;
  color: #333;
}

.add-btn {
  display: flex;
  border: none;
  border-radius: 50%;
  background-color: var(--primary);
  font-size: 1.3vw;
  color: var(--secondary);
  padding: 1vw;
  margin: auto;
}

.question-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3vw;
  color: var(--classi2);
  margin-bottom: 2vh;
}

.marks-picker {
  width: 10vh;
  background-color: var(--primary);
  color: var(--classi2);
  border: none;
  border-radius: 1vw;
  padding: 0.3vw;
  font-size: 1vw;
  text-align: center;
}

.remove-icon {
  cursor: pointer;
  padding: 1vw;
  font-size: 1vw;
  border: 0.1rem solid var(--classi2);
  border-radius: 50%;
}

.remove-icon:hover {
  opacity: 0.6;
}

.question-item {
  margin: 2vw 0vw;
  border-radius: 0.5vw;
}

.step-indicators {
  display: flex;
  justify-content: center;
}

.step-indicator {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 0.5vw;
}

.step-indicator.active {
  background-color: var(--classi2);
}

.file-input {
  display: none;
}

.upload-button {
  color: var(--primary);
  cursor: pointer;
  font-size: 2vw;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  opacity: 0.6;
}

.upload-button:active {
  color: var(--classi2);
}

.custom-file-input.drag-over {
  background-color: #e3f2fd;
  border-color: #2196f3;
}

.drag-drop-text {
  margin-top: 1vh;
  color: #777;
  font-size: 0.7vw;
  line-height: 2;
}

.spinner {
  text-align: center;
  font-size: 1.2vw;
  margin-top: 2vw;
}

.document-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-display {
  height: 55vh;
  width: auto;
  object-fit: contain;
}

.pdf-display {
  margin: auto;
  height: 55vh;
  width: 40vw;
}

.display-buttons {
  display: flex;
  justify-content: space-evenly;
}

.remove-button {
  width: 10vw;
  height: 5vh;
  margin-top: 2vh;
  background-color: crimson;
  color: white;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 0.7vw;
  transition: all 0.3s ease;
}

.remove-button:hover {
  opacity: 0.6;
}

.preview-content {
  background-color: var(--accent1);
  padding: 2rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.preview-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--classi2);
  color: white;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  transition: all ease 0.3s;
  padding: 2vh 2vw;
  margin: 2rem 0 0.25rem 0;
  font-size: 1vw;
}

.question-title {
  flex: 5;
}

.question-number {
  color: darkgrey;
  font-size: 0.8vw;
}

.question-content {
  font-size: 1.1vw;
}

.grade {
  cursor: default;
  display: flex;
  color: var(--primary);
  justify-content: center;
  font-size: 1.2vw;
}

.uploader,
.questions-viewer {
  flex: 1;
  max-width: 50vw;
  margin: auto;
  margin-top: 1vh;
}

.step-heading {
  font-size: 1.5vw;
  margin-bottom: 1vw;
}

.step-description {
  font-size: 1.1vw;
  margin-bottom: 2vw;
}

.small-label {
  margin: 0.5vh;
  font-size: 0.7vw;
  color: grey;
}

.title-input {
  font-size: 1.1vw;
  margin-bottom: 2vw;
  width: 20vw;
  padding: 1vw;
  outline: none;
  border: none;
  border-radius: 1rem;
}

.date-input {
  font-size: 1.1vw;
  margin-bottom: 2vw;
  width: 20vw;
  padding: 1vw;
  outline: none;
  border: none;
  border-radius: 1rem;
}

.submissions-list {
  width: 15vw;
  height: 80vh;
  border-right: 0.1rem solid var(--classi2);
  margin-right: 2vw;
  overflow-y: auto;
}

.submissions-list-heading {
  font-size: 1.4vw;
  padding: 1vw;
  border-bottom: 0.1rem solid var(--classi2);
}

.submission-item {
  word-break: break-all;
  padding: 1vw;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submission-item:hover {
  background-color: var(--classi2);
  color: white;
  transition: ease all 0.3s;
}

.submission-item.active {
  background-color: var(--classi2);
  color: white;
}

.viewer-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
}

.total-marks {
  font-size: 1.3vw;
  color: var(--classi2);
}

.title-date {
  text-align: center;
  color: var(--classi2);
}

.continue-button {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  color: var(--classi2);
  border: 0.1rem solid var(--classi2);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.continue-button:hover {
  background-color: var(--classi2);
  color: white;
}

.question-container {
  margin-bottom: 2vw;
  background-color: var(--secondary);
  /* border: 0.1rem solid var(--classi2); */
  border-radius: 2rem;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

.question-info {
  flex: 3;
  padding: 1.5vw 1vw;
  font-size: 1vw;
}

.question-feedback {
  flex: 1;
  background-color: var(--classi2);
  color: white;
  padding: 1.5vw 1vw;
  font-size: 1vw;
}

.question-header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1vw;
}

.section-break {
  width: 5%;
  height: 0.2vh;
  background-color: var(--classi2);
  margin-right: 1vw;
}

.graded-question-section {
  font-size: 1.3vw;
  color: var(--classi2);
}

.wysiwyg {
  margin-bottom: 2vh;
}

input[type="number"] {
  width: 5vw;
  font-size: 1.3vw;
  text-align: center;
  background-color: transparent;
  color: var(--classi2);
  border: 1px solid var(--classi2);
  border-radius: 0.5rem;
}

.edit-button {
  float: right;
  padding: 1vh 1vw;
  margin-bottom: 1vw;
  display: flex;
  align-items: center;
  font-size: 1.1vw;
  background-color: var(--classi2);
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
}

.edit-button:hover {
  opacity: 0.6;
  transition: ease all 0.3s;
}

.edit-icon {
  padding-left: 0.5vw;
}
</style>
