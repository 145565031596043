<template>
  <div
    ref="tiptapContainer"
    v-if="editor"
    :class="('tiptap-container', { 'show-border': editable })"
    :style="{ borderColor: whiteMode ? 'white' : 'var(--primary)' }"
  >
    <div
      v-if="editable"
      :class="['control-group', 'sticky-toolbar', { whiteMode: whiteMode }]"
    >
      <div class="button-group">
        <button
          @click="editor.chain().focus().toggleBold().run()"
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          <Tooltip text="Bold">
            <span class="material-symbols-outlined"> format_bold </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleItalic().run()"
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          <Tooltip text="Italic">
            <span class="material-symbols-outlined"> format_italic </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleCodeBlock().run()"
          :class="{ 'is-active': editor.isActive('codeBlock') }"
        >
          <Tooltip text="Insert code block">
            <span class="material-symbols-outlined"> code </span>
          </Tooltip>
        </button>
        <button @click="editor.commands.unsetAllMarks()">
          <Tooltip text="Clear formatting">
            <span class="material-symbols-outlined"> format_clear </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{
            'is-active': editor.isActive('heading', {
              level: 1,
            }),
          }"
        >
          <Tooltip text="Heading 1">
            <span class="material-symbols-outlined"> format_h1 </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{
            'is-active': editor.isActive('heading', {
              level: 2,
            }),
          }"
        >
          <Tooltip text="Heading 2">
            <span class="material-symbols-outlined"> format_h2 </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          <Tooltip text="Bullet list">
            <span class="material-symbols-outlined">
              format_list_bulleted
            </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
        >
          <Tooltip text="Numbered list">
            <span class="material-symbols-outlined">
              format_list_numbered
            </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().toggleBlockquote().run()"
          :class="{ 'is-active': editor.isActive('blockquote') }"
        >
          <Tooltip text="Block quote">
            <span class="material-symbols-outlined"> format_quote </span>
          </Tooltip>
        </button>

        <button
          @click="editor.chain().focus().undo().run()"
          :disabled="!editor.can().chain().focus().undo().run()"
        >
          <Tooltip text="Undo">
            <span class="material-symbols-outlined"> undo </span>
          </Tooltip>
        </button>
        <button
          @click="editor.chain().focus().redo().run()"
          :disabled="!editor.can().chain().focus().redo().run()"
        >
          <Tooltip text="Redo">
            <span class="material-symbols-outlined"> redo </span>
          </Tooltip>
        </button>

        <div>
          <button @click="triggerColorPicker">
            <Tooltip text="Text color">
              <span
                class="material-symbols-outlined"
                :style="{
                  color: editor.getAttributes('textStyle').color,
                }"
              >
                format_color_text
              </span>
            </Tooltip>
          </button>
          <input
            ref="colorPicker"
            type="color"
            @input="setColor"
            :value="editor.getAttributes('textStyle').color"
            class="hidden-color-picker"
          />
        </div>

        <div>
          <button @click="triggerHighlightPicker">
            <Tooltip text="Highlighter">
              <span
                class="material-symbols-outlined"
                :style="{
                  color: editor.getAttributes('highlight').color,
                }"
              >
                format_ink_highlighter
              </span>
            </Tooltip>
          </button>
          <input
            ref="highlightPicker"
            type="color"
            @input="setHighlightColor"
            :value="editor.getAttributes('highlight').color"
            class="hidden-highlight-picker"
          />
        </div>
      </div>
    </div>
    <editor-content ref="editor" class="editor" :editor="editor" tabindex="0" />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import Color from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import Placeholder from "@tiptap/extension-placeholder";
import Tooltip from "./Tooltip.vue";
import { Markdown } from "tiptap-markdown";
import { Mathematics } from "@tiptap-pro/extension-mathematics";
import "katex/dist/katex.min.css";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";

export default {
  components: {
    EditorContent,
    Highlight,
    Typography,
    Mathematics,
    Placeholder,
    Tooltip,
  },

  props: ["editable", "content", "whiteMode", "placeholderText"],

  data() {
    return {
      editor: null,
    };
  },
  watch: {
    editable(newValue) {
      this.editor.setOptions({ editable: newValue });
    },
    // content(newContent) {
    //   if (this.editor && !this.editor.isDestroyed) {
    //     // Update the editor's content when the content prop changes
    //     this.editor.commands.setContent(newContent);
    //   }
    // },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        Markdown,
        Typography,
        Mathematics,
        Color.configure({ types: [TextStyle.name, ListItem.name] }),
        TextStyle.configure({ types: [ListItem.name] }),
        StarterKit,
        Placeholder.configure({
          placeholder: this.placeholderText || "Type question here...",
        }),
        Highlight.configure({ multicolor: true }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
      ],
      content: this.content,
      editable: this.editable,
      onUpdate: ({ editor }) => {
        this.$emit("update:content", editor.getJSON());
      },
    });

    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
    this.editor.destroy();
  },

  methods: {
    emitContent() {
      const jsonContent = this.editor.getJSON();

      if (!this.editor.state.doc.textContent.trim().length) {
        this.$emit("update:content", null);
      } else {
        this.$emit("update:content", jsonContent);
      }
    },
    // handleClickOutside(event) {
    //   const tiptapContainer = this.$refs.tiptapContainer;
    //   if (tiptapContainer && !tiptapContainer.contains(event.target)) {
    //     this.editable = false;
    //   }
    // },
    triggerColorPicker() {
      this.$refs.colorPicker.click();
    },
    setColor(event) {
      this.editor.chain().focus().setColor(event.target.value).run();
    },
    triggerHighlightPicker() {
      this.$refs.highlightPicker.click();
    },
    setHighlightColor(event) {
      const color = event.target.value;
      this.editor.chain().focus().toggleHighlight({ color }).run();
    },
  },
};
</script>

<style scoped>
.tiptap-container {
  position: relative;
  width: 100%;
  height: fit-content;
  margin: 1vw 0vw;
  background-color: inherit;
  color: var(--primary);
  box-shadow: none;
  font-family: var(--main-font);
  transition: all ease 0.7s;
  overflow: auto;
  font-size: 0.7vw;
}

.show-border {
  margin: 0 1vw;
  border: 0.1rem solid var(--primary);
  border-radius: 1rem;
  min-width: 30vw;
}

.sticky-toolbar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 10;
}

.control-group {
  padding: 0.5rem;
  border-bottom: 0.05rem solid var(--primary);
  background-color: inherit;
}

.whiteMode {
  border-bottom: 0.05rem solid white;
}

.button-group {
  margin: 0 1rem;
  display: flex;
  transition: all ease 0.3s;
}

.material-symbols-outlined {
  font-size: 1vw;
}

.control-group button {
  cursor: pointer;
  color: var(--primary);
  background-color: inherit;
  border: none;
  border-radius: 0.5vw;
  margin-right: 0.5vw;
  padding: 0.3rem;
}

.whiteMode button {
  color: white;
}

.control-group button:hover {
  background-color: var(--primary);
  color: var(--secondary);
  transition: all ease 0.3s;
}

.editor {
  height: fit-content;
  cursor: text;
  padding: 1vh 1vw;
  line-height: 1.5vw;
  transition: all 0.5s ease;
}

.tiptap {
  outline: none;

  :first-child {
    margin-top: 0;
  }

  strong {
    font-weight: 700;
  }

  ul,
  ol {
    padding: 0 3rem;
  }

  h1,
  h2,
  h3 {
    text-wrap: pretty;
  }

  h1 {
    font-size: 1.5vw;
  }

  h2 {
    font-size: 1.3vw;
  }

  h3 {
    font-size: 1.1vw;
  }

  /* Code and preformatted text styles */
  code {
    border-left: 0.2rem solid var(--primary);
    font-size: 0.8rem;
    padding: 0 0 0 1rem;
    margin: 0;
  }

  pre {
    border-radius: 0.5rem;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      border-left: 0.2rem solid var(--primary);
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--primary);
  }

  p.is-editor-empty:first-child::before {
    color: var(--gray-4);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  table {
    border-collapse: collapse;
    margin: 10vw;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 1px solid var(--gray-3);
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: var(--gray-1);
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: var(--gray-2);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: var(--purple);
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  .tableWrapper {
    margin: 1.5rem 0;
    overflow-x: auto;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}

.hidden-color-picker {
  position: relative;
  width: 0px;
  right: 2.2vw;
  opacity: 0;
  pointer-events: none;
  margin: none;
}

.hidden-highlight-picker {
  position: relative;
  right: 2.2vw;
  width: 0px;
  opacity: 0;
  pointer-events: none;
  padding: none;
}
</style>
