<template>
    <div class="textbook-picker">
        <Dropdown
            v-if="!loading"
            id="textbook-select"
            label="Textbook:"
            :options="dropdownOptions"
            v-model="selectedTextbookId"
            @change="emitSelectedTextbook"
        />
    </div>
</template>

<script>
import axiosInstance from "@/utils/axiosInstance";
import Dropdown from "@/components/Dropdown.vue";

export default {
    components: {
        Dropdown,
    },
    data() {
        return {
            textbooks: [],
            selectedTextbookId: 1,
            loading: true,
        };
    },
    computed: {
        dropdownOptions() {
            return this.textbooks.map((textbook) => ({
                value: textbook.textbook_id,
                text: `${textbook.title} (${textbook.subject})`,
            }));
        },
    },
    methods: {
        async fetchTextbooks() {
            try {
                const textbooksResponse = await axiosInstance.get(
                    "/chatbot/list-textbooks"
                );
                this.textbooks = textbooksResponse.data;
                if (this.textbooks.length > 0) {
                    this.selectedTextbookId = this.textbooks[0].textbookId;
                }
                this.loading = false;
            } catch (error) {
                console.log("Textbooks not found");
            }
        },
        emitSelectedTextbook(textbookId) {
            const selectedTextbook = this.textbooks.find(
                (textbook) => textbook.textbook_id === textbookId
            );

            if (selectedTextbook) {
                this.$emit("textbook-selected", {
                    textbook_id: selectedTextbook.textbook_id,
                    title: selectedTextbook.title,
                    example_queries: selectedTextbook.example_queries,
                });
            }
        },
    },
    mounted() {
        this.fetchTextbooks();
    },
};
</script>

<style scoped>
.textbook-picker {
    margin:auto;
    width: fit-content;
    text-align: left;
    color: var(--primary);
}
</style>
