<template>
    <div class="main-container">
        <div class="section1">
            <div class="header">
                <router-link to="/" class="logo">
                    <span class="classi1">cl</span>
                    <span class="classi2">a</span>
                    <span class="classi1">ss</span>
                    <span class="classi2">i</span>
                </router-link>
                <div style="display: flex; gap: 1vw;">
                    <div class="top-btn" @click="goToCalender">
                        Get in touch
                    </div>
                    <router-link to="/login" class="top-btn">
                        Log In
                    </router-link>
                </div>
            </div>
            <div class="section1-body">
                <div class="tagline">
                    <p>Simplify Teaching</p>
                    <p>Amplify Learning</p>
                    <p style="color: var(--classi2);">Let AI handle the rest</p>
                </div>
                <img :src="dashboardImg" class="dashboard-img" />
            </div>
        </div>
        <div class="section2">
            <div class="byline">
                Classi is a modern, AI-powered learning management system built for the Indian classroom. It is designed
                to enhance <span style="color: var(--classi2);">efficiency</span> for teachers and <span
                    style="color: var(--classi2);">accessibility</span> for students. Here’s how:
            </div>
            <div class="feature-pills-container" ref="pillRef">
                <div class="feature-pill" :class="{ active: activePill === 'chat' }" @click="setActivePill('chat')">
                    Textbook
                    Chat</div>
                <div class="feature-pill" :class="{ active: activePill === 'autograder' }"
                    @click="setActivePill('autograder')">
                    AI Autograder</div>
                <div class="feature-pill" :class="{ active: activePill === 'summarization' }"
                    @click="setActivePill('summarization')">Lecture Summarization</div>
                <div class="active-indicator" :style="indicatorStyle"></div>
            </div>
        </div>
        <div class="section3">
            <GuestChatBox v-if="activePill === 'chat'" />
            <video controls class="video" v-if="activePill === 'autograder'">
                <source :src="autograderVideo" type="video/mp4">
            </video>
            <div class="lecture-sum" v-if="activePill === 'summarization'">
                Coming soon.
            </div>
        </div>
    </div>
</template>

<script>
import GuestChatBox from '@/components/GuestChatBox.vue';
import axiosInstance from '@/utils/axiosInstance';
export default {
    components: {
        GuestChatBox
    },
    data() {
        return {
            dashboardImg: require("@/assets/app_dashboard.png"),
            autograderVideo: require("@/assets/autograder_video.mp4"),
            activePill: 'chat'
        };
    },
    mounted(){
        // Ping server
        const response = axiosInstance.get('/auth/ping');
    },
    methods: {
        scrollToPill() {
            this.$refs.pillRef.scrollIntoView({ behavior: 'smooth' });
        },
        setActivePill(pill) {
            this.activePill = pill;
            this.scrollToPill()
        },
        goToCalender() {            
            window.open('https://calendar.app.google/pqhbri84xNdzwgQQ6', '_blank');
        },
    },
    computed: {
        indicatorStyle() {
            const index = ['chat', 'autograder', 'summarization'].indexOf(this.activePill);
            return {
                left: `calc(${index * 33.33}% - ${index * 1}vh)`, // Adjust position dynamically
            };
        },
    },
}
</script>

<style scoped>
.section1 {
    margin-top: 1.5vh;
    padding: 3vh 3vw;
    background-color: rgb(173, 216, 230, 0.5);
    border-radius: 2rem;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3vh;
}

.logo {
    font-size: 2.5vw;
    font-weight: 100;
    letter-spacing: 1vh;
    font-family: var(--accent-font);
    text-decoration: none;
}

.top-btn {
    background-color: var(--classi2);
    color: white;
    cursor: pointer;
    border-radius: 2rem;
    padding: 1vh 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.top-btn:hover {
    background-color: black;
    transition: ease all 0.5s;
}

.classi1 {
    color: var(--classi1);
    text-transform: none;
}

.classi2 {
    color: var(--classi2);
    text-transform: none;
}

.section1-body {
    display: flex;
    gap: 2vw;
}

.tagline {
    flex: 1;
    font-family: var(--main-font);
    font-size: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
}

.dashboard-img {
    flex: 2;
    width: 50vw;
    border-radius: 2rem;
    box-shadow: var(--box-shadow);
}

.section2 {
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.byline {
    padding: 0 10vw;
    justify-content: center;
    font-size: 1.7vw;
    text-align: center;
    margin-bottom: 4vh;
}

.feature-pills-container {
    position: relative;
    width: 50vw;
    background-color: #d9d9d9;
    border-radius: 2rem;
    display: flex;
    justify-content: space-around;
    padding: 1vh;
}

.active-indicator {
    position: absolute;
    bottom: 1vh;
    height: 5vh;
    margin-left: 1vh;
    width: calc(33.33%);
    background-color: var(--classi2);
    border-radius: 2rem;
    transition: left 0.3s ease;
}

.feature-pill {
    flex: 1;
    padding: 1vh;
    font-size: 1.3vw;
    cursor: pointer;
    text-align: center;
}

.feature-pill.active {
    color: white;
    z-index: 1;
}

.section3 {
    margin-top: 5vh;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.video {
    height: 80vh;
    margin-bottom: 3vh;
    border-radius: 2rem;
    box-shadow: var(--box-shadow);
}

.lecture-sum {
    width: 100%;
    border-radius: 2rem;
    height: 80vh;
    margin-bottom: 3vh;
    padding: 3vh 3vw;
    background-color: rgb(173, 216, 230, 0.5);
    padding-top: 35vh;
    text-align: center;
}
</style>