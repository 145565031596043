<template lang="html">
  <Header />
  <LoadingSpinner v-if="loading" class="spinner" />

  <form v-if="!loading" class="loginContainer" @submit.prevent="login">
    <div class="sliding-button">
      <div class="options">
        <div
          class="option"
          :class="{ active: loginType === 'student' }"
          @click="selectOption('student')"
        >
          Student
        </div>
        <div
          class="option"
          :class="{ active: loginType === 'instructor' }"
          @click="selectOption('instructor')"
        >
          Instructor
        </div>
      </div>
      <div :class="['slider', { moved: loginType === 'instructor' }]" />
    </div>
    <input type="text" v-model="email" placeholder="Email" />
    <input type="password" v-model="password" placeholder="Password" />
    <button type="submit">Login</button>
  </form>
</template>
<script>
import Header from "@/components/Header.vue";
import axiosInstance from "@/utils/axiosInstance";
import { useStudentStore } from "@/store/studentData";
import { useInstructorStore } from "@/store/instructorData";
import { useAssignmentStore } from "@/store/assignmentData";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "LoginPage",
  components: {
    Header,
    LoadingSpinner,
  },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      loginType: "student",
    };
  },
  created() {
    this.studentStore = useStudentStore();
    this.instructorStore = useInstructorStore();
    this.assignmentStore = useAssignmentStore();
  },
  methods: {
    async login() {
      this.loading = true;
      var userId = -1;
      // Login
      try {
        const loginResponse = await axiosInstance.post(
          `/auth/login/${this.loginType}`,
          {
            email: this.email,
            password: this.password,
          }
        );

        userId = loginResponse.data.userId;
      } catch (error) {
        console.error("Invalid credentials", error);
        alert("Email address or password is incorrect");
      }

      if (this.loginType === "student") {
        await this.studentStore.getStudentData();
        await this.assignmentStore.getAssignments();

        this.$router.push("/student/dashboard/").then(() => {
          this.$nextTick(() => {
            this.loading = false;
          });
        });
      } else {
        await this.instructorStore.getInstructorData();
        this.$router.push("/instructor/grader/").then(() => {
          this.$nextTick(() => {
            this.loading = false;
          });
        });
      }
    },
    selectOption(userType) {
      this.loginType = userType;
    },
  },
  async mounted() {
    if (this.studentStore.checkAuth()) {
      this.loading = true;
      await this.assignmentStore.getAssignments();
      this.loading = false;
      this.$router.push("/student/dashboard");
    }
  },
};
</script>

<style scoped>
.spinner {
  margin: 20vh auto;
}

.loginContainer {
  margin: auto;
  display: flex;
  width: 50vw;
  height: 70vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: all ease 0.3s;
  font-size: 1vw;
}

.sliding-button {
  position: relative;
  width: 25vw;
  height: 5vh;
  margin: 2vh auto;
  background-color: var(--primary);
  border-radius: 2rem;
  /* overflow: hidden; */
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.options {
  display: flex;
  height: 5vh;
  color: var(--secondary);
}

.option {
  flex: 1;
  display: flex;
  height: 5vh;
  justify-content: center;
  align-items: center;
  transition: color 0.3s;
  z-index: 2;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: var(--classi2);
  border-radius: 2rem;
  transition: transform 0.3s;
  z-index: 1;
  width: 50%;
}

.moved {
  transform: translateX(100%);
}

input {
  width: 25vw;
  height: 5vh;
  margin: 1rem 0rem;
  padding: 1rem 3rem;
  background-color: var(--background-color);
  color: var(--primary);
  border: 0.15rem solid var(--primary);
  border-radius: 1rem;
  font-size: 1vw;
  outline: none;
}

input:focus {
  box-shadow: none;
  border: 0.15rem solid var(--classi2);
}

button {
  width: 25vw;
  height: 5vh;
  margin: 1vh 0vw;
  padding: 1vh 2vw;
  background-color: var(--classi2);
  color: white;
  border: none;
  border-radius: 1rem;
  font-size: 1vw;
}

button:hover {
  cursor: pointer;
  opacity: 0.6;
}
</style>
